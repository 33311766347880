<template>
  <div v-shortkey="shortkeys" @shortkey="triggerShortkey">
    <div v-if="isReReporting" class="d-flex flex-column justify-content-center align-items-center">
      <div class="general-information mx-auto">
        <h5 class="my-2">Accession Editing</h5>
      </div>
      <div class="col-6 border rounded">
        <select-input class="col" :items="editTypes" label="Edit Type" v-model="editType" />
        <text-area-input
          class="col"
          label="Edit Reason"
          :resize="false"
          rows="5"
          v-model="reasonForChange"
        />
      </div>
    </div>

    <div class="general-information border-bottom d-flex justify-content-start" ref="header">
      <h5 class="my-2">General Information</h5>
      <!-- <button class="btn btn-primary my-1" @click="toggleTablePopup">Tab<u>l</u>es</button> -->
    </div>
    <div class="form-row">
      <contact
        :multiple="true"
        label="Provider"
        class="col"
        ref="provider"
        id="provider"
        name="provider"
        accessKey="p"
        v-model="specimen.contact"
        :validator="$v.specimen.contact"
      />
      <pathologist
        accessKey="p"
        ref="pathologists"
        name="pathologist"
        id="pathologist"
        class="col"
        :validator="$v.specimen.pathologists"
        v-model="specimen.pathologists"
        label="Pathologists"
      />
    </div>
    <div class="form-row">
      <results-macro-tag-box
        ref="resultsMacro"
        class="form-group col mb-0"
        :labelClass="resultsHeaderClass"
        v-model="specimen.resultsMacros"
        :label="macroHeader"
        @clear="toggleClearMacros"
        :interfaceDiagnosis="specimen.interfaceDiagnosis"
        @updateInterfaceDiagnosis="updateInterfaceDiagnosis"
        :macroIsModified="specimen.macroIsModified"
      />

      <div class="form-group col mb-0">
        <label style="font-weight: bold">Interface Diagnosis</label>
        <select-input
          v-model="specimen.interfaceDiagnosis"
          :dataSource="interfaceDiagnosesDataSource"
          valueExpr="displayName"
          :disabled="!permissions.CaseFieldEditDiagnosis"
        />
      </div>
    </div>
    <div v-if="cptDisplay" class="mb-1"><b>Macro CPT Code:</b> {{ cptDisplay }}</div>
    <div class="form-row border-bottom pb-2">
      <ICDCodes
        ref="icd"
        :options-limit="50"
        v-model="specimen.icdCodes"
        :validator="$v.specimen.icdCodes"
        :multiple="true"
        class="text-input col-6 icd-select mb-2"
        trackBy="id"
        :internal-search="false"
        :hide-selected="true"
        placeholder="Search Options"
        :close-on-select="false"
        :shortkey="true"
        @remove="removeICD"
        :checkModified="true"
      />
      <text-input
        class="col-6 mb-2"
        label="Site"
        ref="site"
        name="site"
        v-model="specimen.site"
        accessKey="i"
        :forceUpperCase="upperCaseSite"
      />
    </div>
    <div id="editors" ref="editors" :class="editorClasses">
      <draggable
        v-model="draggableEditors"
        :disabled="!isUnlocked"
        :class="{ unlocked: isUnlocked }"
      >
        <MacroEnabledEditor
          v-for="editor in availableEditors"
          :id="editor.name"
          :key="editor.key"
          :ref="editor.name"
          v-bind="editor"
          :validator="$v.specimen[editor.name]"
          v-model="specimen[editor.name]"
          @toggleSpellChecker="toggleSpellChecker"
          @editorReady="handleAutoOpenEditor"
        />
      </draggable>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-3">
      <button
        v-if="labSettings.MolecularTestRunsEnabled"
        class="btn btn-primary ml-1"
        @click.prevent="toggleAdditionalResults"
      >
        Additional Results
      </button>
      <div v-else />
      <div class="d-flex align-items-center justify-content-end">
        <loader size="small" v-if="isLoading"></loader>
        <button
          :disabled="formDisabled || isLoading"
          type="button"
          class="btn btn-primary ml-4"
          id="saveBtn"
          v-stream:click="saveClick$"
        >
          Save
        </button>
      </div>
    </div>
    <modal :status="isMacroDialogOpen" @close="closeMacroPopup">
      <macro-popup
        @close="isMacroDialogOpen = false"
        :targetType="macroDialogType"
        :targetSpecimen="specimen"
        @macroSelected="macroDialogCallback"
        :dialogFromWysiwyg="macroFromWysiwyg"
      />
    </modal>
    <modal :status="isClearingMacro" @close="toggleClearMacros">
      <form @submit.prevent="handleClearMacroFields" class="p-1 confirm-dialog">
        <h4>Do you want to clear the following fields?</h4>
        <div class="">
          <div class="mb-2">
            <checkbox
              label="Diagnosis"
              name="diagnosis"
              id="diagnosisCheckBox"
              v-model="clearFields.diagnosis"
            />
            <div
              class="p-2 my-1 bg-gray"
              v-show="htmlHasText(specimen.diagnosis)"
              v-html="specimen.diagnosis"
            />
          </div>
          <div class="mb-2">
            <checkbox label="Gross" name="gross" id="grossCheckBox" v-model="clearFields.gross" />
            <div
              class="p-2 my-1 bg-gray"
              v-show="htmlHasText(specimen.gross)"
              v-html="specimen.gross"
            ></div>
          </div>
          <div class="mb-2">
            <checkbox
              label="Microscopic"
              name="microscopic"
              id="microscopicCheckBox"
              v-model="clearFields.microscopic"
            />
            <div
              class="p-2 my-1 bg-gray"
              v-show="htmlHasText(specimen.microscopic)"
              v-html="specimen.microscopic"
            />
          </div>
          <div class="mb-2">
            <checkbox
              label="Specimen Note"
              name="note"
              id="notesCheckBox"
              v-model="clearFields.notes"
            />
            <div
              class="p-2 my-1 bg-gray"
              v-show="htmlHasText(specimen.notes)"
              v-html="specimen.notes"
            />
          </div>
          <div class="mb-2">
            <checkbox
              label="Case Notes"
              name="caseNotes"
              id="caseNotesCheckBox"
              v-model="clearFields.caseNotes"
            />
            <div
              class="p-2 my-1 bg-gray"
              v-show="htmlHasText(specimen.caseNotes)"
              v-html="specimen.caseNotes"
            />
          </div>
          <div class="mb-2">
            <checkbox
              label="Case Macro Holds"
              name="macroHolds"
              id="macroHoldsCheckBox"
              v-model="clearFields.macroHolds"
            />
            <div class="p-2 my-1 bg-gray d-flex flex-wrap" v-if="caseMacroHolds.length > 0">
              <span
                :key="hold.id"
                class="mr-2 btn btn-danger text-white"
                v-for="hold in caseMacroHolds"
              >
                {{ hold.displayName }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <button type="button" @click="isClearingMacro = false" class="btn btn-danger ml-auto">
            No
          </button>
          <button v-focus type="submit" class="btn btn-success ml-2">Yes</button>
        </div>
      </form>
    </modal>
    <modal :status="isTablePopupOpen" @close="toggleTablePopup">
      <TablePopup @close="toggleTablePopup" @submit="handleUpdateTables" />
    </modal>
    <modal :status="isAdditionalResultsOpen" @close="toggleAdditionalResults">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h2>Additional Results</h2>
        <button class="btn btn-danger" @click="toggleEditAdditionalResults">
          {{ isAdditionalResultsEnabled ? "Done" : "Edit" }}
        </button>
      </div>
      <TextAreaInput
        v-model="specimen.additionalResults"
        :disabled="!isAdditionalResultsEnabled"
        ref="additionalResults"
        startHeight="20rem"
      />
      <div class="d-flex align-items-center justify-content-end">
        <button class="btn btn-primary" @click="toggleAdditionalResults">Close</button>
      </div>
    </modal>
  </div>
</template>
<script>
import MacroEnabledEditor from "@/components/common/MacroEnabledEditor";
import DropdownService from "@/services/dropdown";
import ResultsMacroTagBox from "@/components/common/ResultsMacroTagBox.vue";
import MacrosService from "@/services/macros";
import { helpers, required } from "vuelidate/lib/validators";
import ProviderAPI from "@/services/providers";
import { debounce } from "lodash";
import { mapGetters, mapState } from "vuex";
import { Contact, Pathologist, ICDCodes } from "../Selectors";
import TextAreaInput from "../../TextAreaInput.vue";
import eventBus, {
  fromBusEvent,
  ICD_ENGINE_FINISH,
  ICD_ENGINE_START,
  MACRO_START,
  OPEN_MACRO_POPUP,
  REFRESH_EDITORS,
  RESTORE_EDITOR_POSITION,
  SAVE_FROM_CASE_HEADER,
  SPELL_CHECK_ACTION,
  UPDATE_CURRENT_SPECIMEN_PROSTATE,
  USED_RESULTS_MACRO
} from "../../../modules/eventBus";
import { cloneDeep, unset } from "lodash";
import { filter, switchMap, take, map, takeWhile, tap, exhaustMap } from "rxjs/operators";
import SelectInput from "@/components/common/SelectInput.vue";
import MacroPopup from "@/components/MacroPopup.vue";
import Modal from "@/components/common/Modal.vue";
import Loader from "@/components/common/Loader.vue";
import { mergeSpecimensWithMacros } from "@/modules/mergeSpecimensWithMacros";
import Checkbox from "@/components/common/Checkbox.vue";
import {
  getTextFromHtml,
  removeExtraDivs,
  scrollToElement,
  fixProviderPayload,
  addTextToMacro,
  isModalOpen,
  getAltKeys,
  getEditTypes
} from "@/modules/helpers";
import draggable from "vuedraggable";
import { sortBy } from "lodash";
import { initialEditors } from "@/store/ApplicationSettings";
import { CaseEditTypeEnum, MacroTypeEnum, SpecimenIcdOptionalEnum } from "@/modules/enums";
import { interval, merge } from "rxjs";
import elementObserver from "@/modules/elementObserver";
import TextInput from "@/components/common/TextInput.vue";
import { DropdownApi, MacrosApi } from "@/services";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import TablePopup from "@/components/TablePopup.vue";

export default {
  name: "Results-Form",
  props: {
    value: {
      type: Object
    },

    isLoading: {
      type: Boolean
    },
    isUnlocked: Boolean,
    fieldToOpen: String
  },
  components: {
    MacroEnabledEditor,
    Pathologist,
    ResultsMacroTagBox,
    Contact,
    ICDCodes,
    TextAreaInput,
    SelectInput,
    MacroPopup,
    Modal,
    Loader,
    Checkbox,
    draggable,
    TextInput,
    TablePopup
  },
  data() {
    return {
      specimen: {},
      macroDialogType: 0,
      isClearingMacro: false,
      isMacroDialogOpen: false,
      spellcheckRan: false,
      icdOptions: [],
      clearFields: {
        gross: false,
        diagnosis: true,
        notes: true,
        microscopic: true,
        caseNotes: !this.preserveMacroCaseNotes,
        macroHolds: true
      },
      labPrefix: {},
      cptOptions: [],
      icdSearch: DropdownService.searchIcdCodes,
      cptSearch: DropdownService.searchCptCodes,
      mutationObserver: null,
      contactsSearch: ProviderAPI.searchContacts,
      pathologistSearch: DropdownService.searchPathologists,
      microscopic: "",
      caseStatuses: {},
      editTypes: getEditTypes(),
      originalSpecimen: {},
      macroFromWysiwyg: false,
      isIcdRunning: false,
      isMacroRunning: false,
      isSpellChecking: false,
      firstMacroDiagnosticText: {
        diagnosis: null,
        microscopic: null,
        specimenNotes: null,
        time: null
      },
      hasUsedResultsMacro: false,
      isModalOpen: false,
      intervalId: null,
      isTablePopupOpen: false,
      cptDisplay: "",
      shortkeys: getAltKeys("acdglmnoprsuwy"),
      isAdditionalResultsOpen: false,
      isAdditionalResultsEnabled: false,
      isProstateProtocol: false,
      isProstatePopupOpen: false
    };
  },
  domStreams: ["saveClick$"],
  subscriptions() {
    const openMacroFromWysiwyg$ = fromBusEvent(OPEN_MACRO_POPUP).pipe(
      tap(({ type }) => {
        this.macroDialogType = type;
        this.isMacroDialogOpen = true;
        this.macroFromWysiwyg = true;
      }),
      switchMap(({ callback }) => {
        return fromBusEvent(RESTORE_EDITOR_POSITION).pipe(
          tap(macros => {
            this.macroFromWysiwyg = false;
            this.macroDialogType = 0;
            this.isMacroDialogOpen = false;
            callback(macros);
            for (const macro of macros) {
              this.availableEditors.forEach(editor => {
                if (editor.name && macro[editor.name]) {
                  if (this.$refs[editor.name][0]?.expand) {
                    this.$refs[editor.name][0]?.expand();
                  }
                }
              });
            }
          })
        );
      })
    );

    // Get save button click/shortkey
    // Get if ICD engine is running
    const icdEngineStatus$ = this.$watchAsObservable("isIcdRunning", { immediate: true }).pipe(
      filter(({ newValue }) => !newValue),
      tap(() => this.$store.dispatch("logTimeSinceBase", "icd done")),
      take(1)
    );
    const macroStatus$ = this.$watchAsObservable("isMacroRunning", { immediate: true }).pipe(
      filter(({ newValue }) => !newValue),
      tap(() => this.$store.dispatch("logTimeSinceBase", "macro done")),
      take(1)
    );
    const spellCheckStatus$ = this.$watchAsObservable("isSpellChecking", { immediate: true }).pipe(
      filter(({ newValue }) => !newValue),
      tap(() => this.$store.dispatch("logTimeSinceBase", "spell check done")),
      take(1)
    );
    // Wait for both to be done
    const saveAndIcdFinish$ = this.saveClick$.pipe(
      filter(() => !this.formDisabled),
      tap(() => this.$store.commit("setLogTimeBase")),
      switchMap(() => macroStatus$),
      switchMap(() => icdEngineStatus$),
      switchMap(() => spellCheckStatus$),
      // Run save function when both are done
      tap(() => this.saveResults(this.specimen))
    );

    return { openMacroFromWysiwyg$, saveAndIcdFinish$ };
  },
  created() {
    DropdownService.getStatusEnum().then(res => {
      this.caseStatuses = Object.fromEntries(res.map(status => [status.id, status.displayName]));
    });

    this.originalSpecimen = cloneDeep(this.value);
    this.specimen = cloneDeep({
      ...this.value,
      initIcdCodes: this.value?.icdCodes ? this.value.icdCodes : [],
      initCptCodes: this.value?.cptCodes ? this.value.cptCodes : [],
      initResultsMacros: this.value?.resultsMacros ? this.value.resultsMacros : []
    });
    if (this.specimen.resultsMacros?.length && !this.specimen.macroIsModified) {
      this.firstMacroDiagnosticText = {
        diagnosis: this.specimen.diagnosis || null,
        microscopic: this.specimen.microscopic || null,
        specimenNotes: this.specimen.notes || null
      };
    }
    if (this.casePrefix) {
      this.labPrefix = this.casePrefix;
    }
  },
  mounted() {
    //Always opens diagnosis by default.
    this.$nextTick(() => {
      this.setUserFocus();
    });
    if (!this.interfaceDiagnosisOptions.length) {
      this.$store.dispatch("dropdowns/getInterfaceDiagnoses");
    }
    eventBus.$on(ICD_ENGINE_START, () => this.toggleIcd(true));
    eventBus.$on(ICD_ENGINE_FINISH, () => this.toggleIcd(false));
    eventBus.$on(MACRO_START, () => this.startMacroTimeout());
    eventBus.$on(USED_RESULTS_MACRO, macro => this.handleUsedResultsMacro(macro));
    eventBus.$on(UPDATE_CURRENT_SPECIMEN_PROSTATE, data => this.updateProstateData(data));
    eventBus.$on(SAVE_FROM_CASE_HEADER, () => this.triggerShortkey({ srcKey: "s" }));
    this.clearFields = this.defaultClearFields;
    this.checkProtocol();
  },
  beforeDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver?.disconnect();
    }
    eventBus.$off(MACRO_START);
    eventBus.$off(ICD_ENGINE_START);
    eventBus.$off(ICD_ENGINE_FINISH);
    eventBus.$off(USED_RESULTS_MACRO);
    eventBus.$off(UPDATE_CURRENT_SPECIMEN_PROSTATE);
    eventBus.$off(SAVE_FROM_CASE_HEADER);
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      sessionDetails: state => state.sessionDetails,
      lastPathologist: state => state.sessionDetails.lastPathologist,
      textEditors: state => state.applicationSettings.textEditors || initialEditors,
      automaticMacroPopup: state =>
        state.applicationSettings.automaticMacroPopup !== null
          ? state.applicationSettings.automaticMacroPopup
          : state.labSettings.AutoMacroPopup,
      caseEditType: state => state.accessionStore.editType,
      caseDetails: state => state.accessionStore.caseDetails,
      casePrefix: state => state.accessionStore.casePrefix,
      caseHolds: state => state.accessionStore.caseHolds,
      specimens: state => state.accessionStore.specimens,
      SpellCheckOnSave: state => state.labSettings.SpellCheckOnSave,
      autoOpenEditors: state => state.applicationSettings.autoOpenEditors,
      dashboardMode: state => state.applicationSettings.accessionMode,
      confirmClearResultsMacro: state => state.applicationSettings.confirmClearResultsMacro,
      preserveMacroCaseNotes: state => state.applicationSettings.preserveMacroCaseNotes,
      ForceUpperCaseSite: state => state.labSettings.ForceUpperCaseSite,
      resultsMacroRetainScroll: state => state.applicationSettings.resultsMacroRetainScroll,
      autoOpenFirstEditorResults: state => state.applicationSettings.autoOpenFirstEditorResults,
      interfaceDiagnosisOptions: state => state.dropdowns.interfaceDiagnoses
    }),
    ...mapGetters("accessionStore", ["isCaseEditable", "isReported", "primaryPathologist"]),
    ...mapGetters(["permissions", "webSpellcheckerLoaded"]),
    isReReporting() {
      return [
        CaseEditTypeEnum.Amended,
        CaseEditTypeEnum.Addendum,
        CaseEditTypeEnum.Corrected,
        CaseEditTypeEnum.NonDiagnostic,
        CaseEditTypeEnum.Other,
        CaseEditTypeEnum.Billing
      ].includes(this.caseEditType);
    },
    editorClasses() {
      return {
        upperCaseClinical: parseInt(this.labSettings.ForceUpperCaseClinical) || false,
        upperCaseSpecimenNote: parseInt(this.labSettings.ForceUpperCaseSpecimenNote) || false,
        upperCaseMicroscopic: parseInt(this.labSettings.ForceUpperCaseMicroscopic) || false,
        upperCaseCaseNote: parseInt(this.labSettings.ForceUpperCaseCaseNote) || false,
        upperCaseDiagnosis: parseInt(this.labSettings.ForceUpperCaseDiagnosis) || false,
        upperCaseGross: parseInt(this.labSettings.ForceUpperCaseGross) || false
      };
    },
    editType: {
      get() {
        return this.caseEditType;
      },
      set(value) {
        this.$store.commit("accessionStore/setEditType", value);
        return value;
      }
    },
    availableEditors() {
      const {
        CaseFieldEditDiagnosis,
        CaseFieldEditSpecimenNotes,
        CaseFieldEditMicroscopic,
        CaseFieldEditGross,
        CaseFieldEditCaseNotes,
        CaseFieldEditClinical,
        CaseFieldEditSynoptic
      } = this.permissions;
      let key = "";
      if (this.specimen && this.specimen?.id) {
        key = this.specimen.id;
      }
      const editors = sortBy(
        [
          CaseFieldEditDiagnosis && { ...this.textEditors.diagnosis, name: "diagnosis" },
          CaseFieldEditSpecimenNotes && {
            name: "notes",
            ...this.textEditors.notes
          },
          CaseFieldEditMicroscopic && { name: "microscopic", ...this.textEditors.microscopic },
          CaseFieldEditGross && { name: "gross", ...this.textEditors.gross },
          CaseFieldEditCaseNotes && {
            name: "caseNotes",
            ...this.textEditors.caseNotes,
            accessKey: "a"
          },
          CaseFieldEditClinical && { name: "clinical", ...this.textEditors.clinical },
          this.labSettings.UseSynopticTextField &&
            CaseFieldEditSynoptic && { name: "synopticText", ...this.textEditors.synopticText }
        ],
        "resultsOrder"
      );
      editors.forEach(e => {
        return e ? (e.key = `${e?.name}-${key}`) : e;
      });
      return editors.filter(value => value);
    },
    reasonForChange: {
      get() {
        return this.$store.state.accessionStore.reasonForChange;
      },
      set(value) {
        this.$store.commit("accessionStore/setReasonForChange", value);
        return value;
      }
    },
    formDisabled() {
      if (this.isLoading) {
        return true;
      }
      if (this.isReported) {
        if (this.isCaseEditable) {
          return [CaseEditTypeEnum.NonDiagnostic, CaseEditTypeEnum.Billing].includes(
            this.caseEditType
          );
        }
        return true;
      } else if (this.isCaseEditable) {
        return !this.permissions.CaseResultsCreateEdit;
      } else {
        return !this.isCaseEditable;
      }
    },
    caseMacroHolds() {
      return this.caseHolds.filter(hold => hold.text && hold.text.includes("Added by Macro"));
    },

    billingCPTWarning() {
      return parseInt(this.labSettings.BillingCPTWarning) || false;
    },
    resultsHeaderClass() {
      if (/\(M\)/i.test(this.macroHeader)) {
        return { "text-danger": true };
      }
      return { "text-danger": false };
    },
    draggableEditors: {
      get() {
        return this.availableEditors;
      },
      set(value) {
        const newEditors = value
          .map((editor, idx) => {
            return {
              ...editor,
              resultsOrder: idx
            };
          })
          .reduce((acc, curr) => {
            acc[curr.name] = curr;
            return acc;
          }, {});
        this.$store.commit("applicationSettings/setEditorsOrder", {
          ...this.textEditors,
          ...newEditors
        });
      }
    },
    interfaceDiagnosesDataSource() {
      return new DataSource({
        store: new ArrayStore({ data: this.interfaceDiagnosisOptions })
      });
    },
    macroHeader() {
      return `<u>R</u>es<u>u</u>lts Macros${
        this.specimen.macroIsModified ? " <span class='text-danger'>(modified)</span>" : ""
      }`;
    },
    defaultClearFields() {
      return {
        gross: false,
        diagnosis: true,
        notes: true,
        microscopic: true,
        caseNotes: !this.preserveMacroCaseNotes,
        macroHolds: true
      };
    },
    upperCaseSite() {
      if (Number(this.ForceUpperCaseSite)) {
        return true;
      }
      return false;
    }
  },
  validations() {
    return {
      specimen: {
        contact: {
          required
        },
        pathologists: {
          required
        },
        diagnosis: {
          required
        },
        icdCodes: {
          required: vm => {
            return !this.labSettings.SpecimenICDOptional && this.casePrefix.isBillingEnabled
              ? helpers.req(vm)
              : true;
          }
        }
      }
    };
  },
  watch: {
    specimen: {
      deep: true,
      handler(nv, ov) {
        this.$store.commit("accessionStore/setCurrentSpecimen", nv);
        if (ov && nv?.id !== ov?.id) {
          this.hasUsedResultsMacro = nv?.macroIsModified || false;
          for (const field of ["diagnosis", "microscopic", "specimenNotes"]) {
            this.firstMacroDiagnosticText[field] = nv[field] || null;
            this.checkIfMacroFieldModified(field, nv[field]);
          }
        }
      }
    },
    value: {
      handler(nv, ov) {
        if (ov && ov?.id !== nv?.id) {
          this.$nextTick(() => {
            return this.setUserFocus();
          });
        }

        this.specimen = cloneDeep({
          ...nv,
          initIcdCodes: nv?.icdCodes ? nv.icdCodes : [],
          initCptCodes: nv?.cptCodes ? nv.cptCodes : [],
          initResultsMacros: nv?.resultsMacros ? nv.resultsMacros : []
        });
      }
    },
    "specimen.pathologists": {
      deep: true,
      handler(value) {
        const anyPrimary = value.filter(e => e.isPrimary);
        if ((!anyPrimary.length && value.length) || anyPrimary.length > 1) {
          this.setPrimaryPathologist(value[0].id);
        }
      }
    },
    isClearingMacro(nv) {
      if (!nv) {
        this.clearFields = this.defaultClearFields;
      }
    },
    "specimen.interfaceDiagnosis": {
      immediate: true,
      handler(nv) {
        if (nv && !this.interfaceDiagnosisOptions.find(e => e.displayName === nv)) {
          const diagnosisName = this.interfaceDiagnosisOptions.find(
            e => e.displayName.toLowerCase() === nv.toLowerCase()
          );
          if (diagnosisName) {
            this.specimen.interfaceDiagnosis = diagnosisName.displayName;
          }
        }
      }
    },
    "specimen.diagnosis": {
      immediate: false,
      handler(nv) {
        this.checkIfMacroFieldModified("diagnosis", nv);
      }
    },
    "specimen.microscopic": {
      immediate: false,
      handler(nv) {
        this.checkIfMacroFieldModified("microscopic", nv);
      }
    },
    "specimen.notes": {
      immediate: false,
      handler(nv) {
        this.checkIfMacroFieldModified("specimenNotes", nv);
      }
    },
    "specimen.resultsMacros": {
      immediate: true,
      handler(nv) {
        this.getCptDisplay(nv);
      }
    }
  },
  methods: {
    toggleClearMacros() {
      this.isClearingMacro = !this.isClearingMacro;
    },
    triggerShortkey(event, isManual) {
      if (isModalOpen() && !isManual) {
        return;
      }
      if (event.srcKey !== "o") {
        this.closeIcdIfOpen();
      }
      switch (event.srcKey) {
        case "p":
          if (!this.$refs.provider.isFocused) {
            this.$refs.provider.focus();
          } else {
            this.$refs.pathologists.focus();
          }
          break;
        case "r":
          if (!this.isMacroDialogOpen) {
            scrollToElement(this.$refs.resultsMacro.$el);
          }
          this.macroDialogType = MacroTypeEnum.Results;
          this.isMacroDialogOpen = !this.isMacroDialogOpen;
          break;
        case "o":
          if (!this.$refs.icd.status) {
            scrollToElement(this.$refs.icd.$el);
          }
          this.$nextTick(this.$refs.icd.toggleDropdown());
          break;
        case "u":
          this.$refs.resultsMacro.focusMacro();
          scrollToElement(this.$refs.resultsMacro.$el);
          break;
        case "w":
          this.handleClearMacroShortkey();
          break;
        case "s":
          if (!this.formDisabled) {
            this.$store.commit("accessionStore/setLoading", true);
            this.saveClick$.next();
          }
          break;
        case "b":
          if (this.isProstateProtocol) {
            this.isProstatePopupOpen = true;
          }
          break;
        case "l":
          // Removed table logic - key is available
          // this.toggleTablePopup();
          break;
        default:
          {
            const targetEditorName = this.availableEditors.find(
              e => e.accessKey === event.srcKey
            ).name;
            this.$refs[targetEditorName][0].focus(true);
          }
          break;
      }
    },
    async handleClearMacroFields(skipFocus) {
      const fields = Object.keys(this.clearFields).filter(e => this.clearFields[e]);
      this.specimen.icdCodes = this.specimen.icdCodes.filter(icdCode => icdCode.isManual);
      if (!this.specimen.icdCodes?.length) {
        this.specimen.isICDModified = false;
      }
      this.specimen.interfaceDiagnosis = "";
      fields.forEach(e => {
        this.specimen[e] = "";
      });
      if (this.caseMacroHolds.length) {
        await this.$store.dispatch("accessionStore/removeQuickLink", {
          type: "H",
          caseId: this.specimen.caseId,
          ids: this.caseMacroHolds.map(e => e.id)
        });
      }
      this.isClearingMacro = false;
      this.clearFields = this.defaultClearFields;
      this.firstMacroDiagnosticText = {
        diagnosis: null,
        microscopic: null,
        specimenNotes: null,
        time: null
      };
      this.hasUsedResultsMacro = false;
      setTimeout(() => eventBus.$emit(REFRESH_EDITORS), 0);
      window.notify("Cleared Results Macros.");
      if (!skipFocus) {
        this.openEditors();
      }
    },
    openEditors() {
      this.triggerShortkey({ srcKey: "d" }, true);
    },
    htmlHasText(htmlString) {
      if (htmlString) {
        return !!getTextFromHtml(htmlString).trim();
      }
      return false;
    },
    async checkEditorSpelling() {
      const spellcheck$ = fromBusEvent(SPELL_CHECK_ACTION).pipe(
        filter(({ data }) => data.type === "spelling"),
        switchMap(({ instance }) => {
          return interval(500).pipe(map(() => instance.getProblemsCount()));
        })
      );
      const closeDialog$ = interval(500).pipe(
        map(() => {
          const dialogElement = document.querySelector(
            "div.wsc-dialog.wsc-theme-custom.wsc-element.wsc--border-box:not(.wsc--hidden)"
          );
          return dialogElement;
        }),
        filter(e => e != null),
        exhaustMap(wscDialogEl => elementObserver(wscDialogEl)),
        map(([mutations]) => {
          if (Array.isArray(mutations)) {
            for (const mutation of mutations) {
              const { target } = mutation;
              if (target.classList.contains("wsc--hidden")) {
                return 0;
              }
            }
          }
          return 1;
        })
      );
      if (typeof window.WEBSPELLCHECKER !== "undefined" && window.WEBSPELLCHECKER?.init) {
        const spellCheckers = window.WEBSPELLCHECKER.getInstances();
        for (const spellChecker of spellCheckers) {
          if (spellChecker.getProblemsCount && spellChecker.getProblemsCount()) {
            const instanceNode = spellChecker.getContainerNode();
            let editorRef = this.$refs[instanceNode.id];
            if (Array.isArray(editorRef)) {
              editorRef = editorRef[0];
              if (editorRef.expand) {
                editorRef.expand();
              }
            }
            await this.$nextTick();
            scrollToElement(instanceNode);

            spellChecker.openDialog();
            await merge(closeDialog$, spellcheck$)
              .pipe(takeWhile(val => val > 0))
              .toPromise();
            if (editorRef.collapse) {
              editorRef.collapse();
              await this.$nextTick();
            }
          }
        }
        const dialog = document.querySelector(
          ".wsc-dialog.wsc-element.wsc--border-box:not(.wsc--hidden)"
        );
        if (dialog !== null) {
          dialog.classList.toggle("wsc--hidden");
        }
      }
    },
    async saveResults(specimen) {
      this.$store.dispatch("logTimeSinceBase", "save function triggered");
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("accessionStore/setLoading", false);
        return window.notify("Please check your input and try again.", "error");
      }
      if (specimen.pathologists?.length) {
        const primaryPathologist = specimen.pathologists.find(e => e?.isPrimary);
        if (primaryPathologist) {
          this.$store.commit("sessionDetails/setLastPathologist", primaryPathologist);
        }
      }
      if (
        this.casePrefix?.isBillingEnabled &&
        this.billingCPTWarning &&
        !this.specimen.cptCodes?.length
      ) {
        const confirm = await window.confirm(
          `No cpt codes found, therefore automatic billing will not take place. <br> Would you like to continue?`
        );
        if (!confirm) {
          return;
        }
      }
      if (this.SpellCheckOnSave) {
        await this.checkEditorSpelling();
      }
      const newCptCodes = specimen.cptCodes?.filter(
        e => specimen.initCptCodes?.findIndex(val => val.id === e.id) === -1
      );
      const deletedCptCodes = specimen.initCptCodes?.filter(
        e => specimen.cptCodes?.findIndex(val => val.id === e.id) === -1
      );
      const updatedCptCodes = specimen.initCptCodes
        ?.map(val => {
          if (deletedCptCodes?.findIndex(elem => elem.id === val.id) >= 0) {
            return { ...val, isDeleted: true };
          } else {
            return { ...val, isDeleted: false };
          }
        })
        .concat(newCptCodes);
      const newIcdCodes = specimen.icdCodes?.filter(
        e => specimen.initIcdCodes?.findIndex(val => val.id === e.id) === -1
      );
      const deletedIcdCodes = specimen.initIcdCodes?.filter(
        e => specimen.icdCodes?.findIndex(val => val.id === e.id) === -1
      );
      const updatedIcdCodes = specimen.initIcdCodes
        ?.map(val => {
          if (deletedIcdCodes?.findIndex(elem => elem.id === val.id) >= 0) {
            return { ...val, isDeleted: true };
          } else {
            return { ...val, isDeleted: false };
          }
        })
        .concat(newIcdCodes);
      const newResultsMacros = specimen.resultsMacros?.filter(
        e => specimen.initResultsMacros?.findIndex(val => val.id === e.id) === -1
      );
      const deleteResultsMacros = specimen.initResultsMacros?.filter(
        e => specimen.resultsMacros?.findIndex(val => val.id === e.id) === -1
      );
      const updatedResultsMacros = specimen.initResultsMacros
        ?.map(val => {
          if (deleteResultsMacros?.findIndex(elem => elem.id === val.id) >= 0) {
            return { ...val, isDeleted: true };
          } else {
            return { ...val, isDeleted: false };
          }
        })
        .concat(newResultsMacros);
      const billableIcdCode = specimen.icdCodes.find(code => code.isBillable);
      if (
        !billableIcdCode?.id &&
        this.casePrefix?.isBillingEnabled &&
        this.labSettings.SpecimenICDOptional !== SpecimenIcdOptionalEnum.On
      ) {
        const confirm = await window.confirm(
          "Specimens must have at least one billable icd code. <br/> Do you wish to continue? "
        );
        if (!confirm) {
          return;
        }
      }
      unset(specimen, "initIcdCodes");
      unset(specimen, "initCptCodes");
      unset(specimen, "initResultsMacros");
      for (const editor of this.draggableEditors) {
        this.specimen[editor.name] = removeExtraDivs(this.specimen[editor.name]);
      }
      specimen.contact = fixProviderPayload(specimen.contact);
      return this.$emit("save", {
        ...specimen,
        icdCodes: updatedIcdCodes,
        cptCodes: updatedCptCodes,
        resultsMacros: updatedResultsMacros
      });
    },
    async macroDialogCallback(macros) {
      if (macros.length > 1 && macros.find(e => e.macroType === MacroTypeEnum.Results)) {
        this.isMacroModified = true;
      } else if (macros[0].macroType === MacroTypeEnum.Results) {
        this.handleUsedResultsMacro(macros[0]);
      }
      this.isMacroDialogOpen = false;
      macros[0].timeUsed = new Date().getTime();
      const updatedSpecimens = await mergeSpecimensWithMacros(
        [this.specimen],
        macros,
        macros.map(macro => `.${macro.macroName}`).join("")
      );
      this.specimen = updatedSpecimens[0];

      this.$nextTick(async () => {
        const editor = this.$refs.diagnosis[0].$refs.editor;
        if (editor.lastSelection?.index) {
          editor.lastSelection = null;
        }
        await this.$store.dispatch("accessionStore/useMacroOnCurrentSpecimen", { macros });
        if (this.resultsMacroRetainScroll) {
          scrollToElement(this.$refs.header);
        } else {
          this.triggerShortkey({ srcKey: "d" }, true);
        }
      });
    },
    async setUserFocus() {
      const shortkeyToTrigger =
        this.fieldToOpen ||
        (this.autoOpenFirstEditorResults ? this.availableEditors[0].accessKey : "d");
      if (!this.primaryPathologist?.id) {
        if (this.lastPathologist?.id) {
          this.specimen.pathologists.push({
            ...this.lastPathologist,
            isPrimary: true,
            lastUsed: true
          });
        } else {
          this.$refs.pathologists?.focus();
          return this.$watchAsObservable("specimen.pathologists")
            .pipe(
              filter(({ newValue, oldValue }) => {
                if (!this.automaticMacroPopup) {
                  return false;
                }
                if (newValue.length && !oldValue.length) {
                  return !this.specimen.diagnosis?.length;
                }
                return false;
              }),
              take(1)
            )
            .toPromise()
            .then(() => {
              if (this.automaticMacroPopup) {
                this.macroDialogType = 0;
                this.isMacroDialogOpen = true;
              } else if (this.resultsMacroRetainScroll) {
                scrollToElement(this.$refs.header);
              } else {
                this.triggerShortkey({ srcKey: shortkeyToTrigger }, true);
              }
            });
        }
      }
      if (this.automaticMacroPopup && !this.specimen?.diagnosis) {
        this.macroDialogType = 0;
        this.isMacroDialogOpen = true;
      } else if (this.resultsMacroRetainScroll) {
        scrollToElement(this.$refs.header);
      } else {
        setTimeout(() => {
          this.triggerShortkey({ srcKey: shortkeyToTrigger }, true);
        }, 500);
      }
    },
    checkMods() {
      if (this.value) {
        if (!this.specimen.macroIsModified) {
          if (this.specimen.resultsMacros?.length) {
            Promise.all(
              this.specimen.resultsMacros.map(e => MacrosService.getMacroDetails(e.id))
            ).then(macros => {
              const macroWithMicroscopic = macros.find(e => Boolean(e.microscopic));
              if (macroWithMicroscopic) {
                this.mutationObserver = new MutationObserver(this.applyMods);
                if (this.$refs.editors) {
                  this.mutationObserver.observe(this.$refs.editors, {
                    characterData: true,
                    subtree: true,
                    characterDataOldValue: true
                  });
                }
                return (this.macroHeader = "<u>R</u>esults Macros(R)");
              } else {
                return (this.macroHeader = "<u>R</u>esults Macros(G)");
              }
            });
          } else {
            return (this.macroHeader = "<u>R</u>esults Macros");
          }
        } else {
          return (this.macroHeader = "<u>R</u>esults Macros (M)");
        }
      }
    },
    applyMods: debounce(function (data) {
      if (!this.specimen.resultsMacros?.length) {
        return;
      }
      if (data.length) {
        const reducedMutations = data.reduce((acc, curr, i, arr) => {
          if (!acc.length) {
            return (acc = [curr]);
          } else if (arr[i - 1]?.target != curr.target) {
            return acc.push(curr);
          }
          return acc;
        }, []);
        if (reducedMutations.length) {
          for (const mutation of reducedMutations) {
            const { oldValue } = mutation;
            const specimenRegex = /((?:([.|\\])[\w+-]+)+),([\w*>=<]+)/i;
            if (
              //Specimen macros check.
              specimenRegex.test(oldValue) ||
              //Direct Macro check TODO
              //Group macro check
              /[.]{2}[a-zA-Z]+[\s|\n]?/im.test(oldValue)
            ) {
              return;
            } else {
              this.specimen.macroIsModified = true;
              this.mutationObserver.disconnect();
              return this.checkMods();
            }
          }
        }
      }
    }, 1800),
    buildTitle(specimen) {
      let specimenOrder = specimen.specimenOrder || "";
      let protocol = this.mappedProtocol[specimen.protocolId] || "";
      let bodyPart = this.mappedBodyPart[specimen.bodyPartId]
        ? this.mappedBodyPart[specimen.bodyPartId] + ","
        : "";
      let site = this.mappedSite[specimen.siteId] ? this.mappedSite[specimen.siteId] + "," : "";
      return specimenOrder + " " + site + " " + bodyPart + " " + protocol;
    },
    setPrimaryPathologist(id) {
      this.specimen.pathologists = this.specimen.pathologists.map(entry => {
        if (entry?.id === id) {
          entry.isPrimary = true;
        } else {
          entry.isPrimary = false;
        }
        return entry;
      });
    },
    appendMacro(macro) {
      if (macro.macroType === 0 || macro.macroType === 5) {
        if (macro.microscopic) {
          this.macroHeader = "Results Macros (R)";
          if (this.mutationObserver) {
            this.mutationObserver.disconnect();
          }
        }
        if (this.specimen) {
          this.specimen.resultsMacros.push({
            id: macro.macroId,
            displayName: macro.macroName,
            isDeleted: false
          });
          if (!this.specimen.macroIsModified) {
            if (this.mutationObserver) {
              this.mutationObserver.disconnect();
            } else {
              this.mutationObserver = new MutationObserver(this.applyMods);
            }
            if (this.$refs.editors) {
              this.mutationObserver.observe(this.$refs.editors, {
                characterData: true,
                subtree: true,
                characterDataOldValue: true
              });
            }
          }
        }
      }
    },
    removeSelectedMacro(specimen, index) {
      if (specimen) {
        specimen.resultsMacros.splice(index, 1);
      }
    },
    closeIcdIfOpen() {
      if (this.$refs.icd.status) {
        this.$refs.icd.toggleDropdown();
      }
    },
    removeICD(id) {
      this.specimen.icdCodes = this.specimen.icdCodes.filter(e => e.id !== id);
      this.specimen.isICDModified = true;
    },
    closeMacroPopup() {
      this.isMacroDialogOpen = false;
      if (this.dashboardMode === "result") {
        this.$refs.diagnosis[0].focus();
      }
    },
    updateInterfaceDiagnosis(newinterfaceDiagnosis) {
      this.specimen.interfaceDiagnosis = newinterfaceDiagnosis;
    },
    toggleIcd(status) {
      this.isIcdRunning = status;
    },
    startMacroTimeout() {
      this.isMacroRunning = true;
      setTimeout(() => {
        this.isMacroRunning = false;
      }, 1000);
    },
    toggleSpellChecker(value) {
      this.isSpellChecking = value;
    },
    handleUsedResultsMacro(macro) {
      if (this.hasUsedResultsMacro) {
        this.specimen.macroIsModified = true;
        return;
      }
      this.hasUsedResultsMacro = true;
      const diagnosticFields = ["diagnosis", "microscopic", "specimenNotes"];
      for (const field of diagnosticFields) {
        if (this.firstMacroDiagnosticText[field] !== null) {
          this.specimen.macroIsModified = true;
        }
      }
      if (!this.specimen.macroIsModified) {
        for (const field of diagnosticFields) {
          this.firstMacroDiagnosticText[field] = getTextFromHtml(macro[field]) || "";
        }
        this.firstMacroDiagnosticText.time = macro?.timeUsed || new Date().getTime();
      }
    },
    checkIfMacroFieldModified(field, text) {
      if (!this.specimen.resultsMacros?.length) {
        // If there are no results macro, it is marked as not modified
        this.specimen.macroIsModified = false;
        return;
      }
      if (!getTextFromHtml(text)) {
        // If there is no text and no results macros, it is marked as not modified
        if (!this.specimen.resultsMacros?.length) {
          this.specimen.macroIsModified = false;
          return;
        } else if (this.firstMacroDiagnosticText[field]) {
          // If there is no text and the macro had text, it is marked as modified
          this.specimen.macroIsModified = true;
          return;
        }
      }
      if (this.specimen.resultsMacros?.length > 1) {
        // If there are multiple results macros, it is marked as modified.
        this.specimen.macroIsModified = true;
        return;
      }
      if (this.specimen.resultsMacros && this.specimen.macroIsModified) {
        // If there is already a reults macro and the specimen has already been modified, the function returns.
        return;
      }
      const fieldText = getTextFromHtml(text).replace(/ $/, "").replaceAll(/\s+/g, " ");
      const macroText =
        getTextFromHtml(this.firstMacroDiagnosticText[field])
          ?.replace(/ $/, "")
          .replaceAll(/\s+/g, " ") || "";
      // Since we can add time to macros, we have to make sure the time text is the same
      const time = this.firstMacroDiagnosticText?.timeUsed || new Date().getTime();
      if (
        this.firstMacroDiagnosticText[field] !== null &&
        fieldText !== addTextToMacro(macroText, time)
      ) {
        // If the field has text and has changed since the macro was first used, it is marked as modified.
        this.specimen.macroIsModified = true;
      } else {
        // If the macro field has no text or they are the same, it is marked as not modified
        return false;
      }
    },
    async handleClearMacroShortkey() {
      if (!this.specimen.resultsMacros.length) {
        window.alert("This specimen does not have any results macros.");
        return;
      }
      if (this.confirmClearResultsMacro) {
        const confirm = await window.confirm(
          "Do you want to clear all result macros from this specimen?"
        );
        if (!confirm) {
          return;
        }
      }
      for (const macro of this.specimen.resultsMacros) {
        this.$refs.resultsMacro.removeMacro(macro.id);
      }
      this.specimen.resultsMacros = [];
      this.handleClearMacroFields();
    },
    async getCptDisplay(resultsMacros) {
      let text = "";
      if (resultsMacros?.length) {
        const macro = await MacrosApi.getMacroDetails(resultsMacros[0].id);
        if (macro.cptCodes?.length) {
          const cptCode = macro.cptCodes[0];
          if (!cptCode?.displayName) {
            text = cptCode.displayName;
          } else {
            const cptCodeData = await DropdownApi.searchCptCodes.load({
              filter: ["id", "=", cptCode.id]
            });
            text = cptCodeData[0].displayName || `[ID: ${cptCodeData[0].id}]`;
          }
        }
      }
      this.cptDisplay = text;
    },
    toggleAdditionalResults() {
      this.isAdditionalResultsOpen = !this.isAdditionalResultsOpen;
      this.isAdditionalResultsEnabled = false;
    },
    toggleEditAdditionalResults() {
      if (!this.isAdditionalResultsEnabled) {
        this.isAdditionalResultsEnabled = true;
        this.$nextTick(() => this.$refs.additionalResults.focus());
      } else {
        this.isAdditionalResultsEnabled = false;
      }
    },
    handleAutoOpenEditor(editorName) {
      if (this.autoOpenEditors && this.$refs[editorName][0]?.expand) {
        this.$refs[editorName][0].expand();
      }
    },
    async checkProtocol() {
      if (this.specimen.protocolId) {
        const protocol = await MacrosApi.getMacroDetails(this.specimen.protocolId);
        this.isProstateProtocol = protocol?.isProstate;
      } else {
        this.isProstateProtocol = false;
      }
    },
    updateProstateData(data) {
      this.specimen = { ...this.specimen, ...data };
    },
    toggleTablePopup() {
      console.log("table popup disabled");
      // this.isTablePopupOpen = !this.isTablePopupOpen;
    },
    handleUpdateTables(data) {
      this.specimen.tables = data;
      this.toggleTablePopup();
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 0.25rem;
}

.result_macro {
  background-color: $primary;
}
.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: $primary;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}
.tag-btn {
  padding: 0px;
  margin: 0px;
}
.bg-gray {
  background-color: $gray;
}
@media screen and (min-width: 768px) {
  .confirm-dialog {
    width: 500px;
  }
}

.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: $primary;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
}
#editors {
  max-width: 100%;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
